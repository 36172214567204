var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{directives:[{name:"attributes",rawName:"v-attributes",value:('input'),expression:"'input'"}],class:_vm.schema.fieldClasses,attrs:{"id":"period-wrapper","name":_vm.schema.inputName}},[_c('label',{staticClass:"hint",attrs:{"for":"`${schema.attributes.input.inputIdPrefix}-start`"}},[_vm._v(" Fecha de inicio ")]),_vm._v(" Period>> "+_vm._s(_vm.period)+" "),(_vm.dateNow)?_c('datetime',{staticClass:"form-control",class:_vm.schema.fieldClasses,attrs:{"max-datetime":_vm.dateNow,"input-id":`${_vm.schema.attributes.input.inputIdPrefix}-start`,"name":_vm.schema.inputName,"value-zone":"America/Mexico_City","format":{
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },"phrases":{ ok: 'Continuar', cancel: 'Salir' },"week-start":1,"use12-hour":"","title":`Inicio de ${_vm.schema.title}`,"auto":""},model:{value:(_vm.period.start),callback:function ($$v) {_vm.$set(_vm.period, "start", $$v)},expression:"period.start"}}):_vm._e(),_c('label',{staticClass:"hint",attrs:{"for":"`${schema.attributes.input.inputIdPrefix}-end`"}},[_vm._v(" Fecha de terminación ")]),_c('datetime',{staticClass:"form-control",class:_vm.schema.fieldClasses,attrs:{"disabled":!_vm.period.start,"min-datetime":_vm.period.start,"input-id":`${_vm.schema.attributes.input.inputIdPrefix}-end`,"name":_vm.schema.inputName,"value-zone":"America/Mexico_City","format":{
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      },"phrases":{ ok: 'Continuar', cancel: 'Salir' },"week-start":1,"use12-hour":"","title":`Fin de ${_vm.schema.title}`,"auto":""},model:{value:(_vm.period.end),callback:function ($$v) {_vm.$set(_vm.period, "end", $$v)},expression:"period.end"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }