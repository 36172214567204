// import state from './mail.state'
// import mutations from './mail.mutations'
import actions from './mail.actions'
// import getters from './mail.getters'

export default {
  namespaced: true,
  //   state,
  //   mutations,
  actions,
  //   getters,
}
