// import state from './user.state'
// import mutations from './user.mutations'
import actions from './user.actions'
// import getters from './user.getters'

export default {
  namespaced: true,
  // state,
  // mutations,
  actions,
  // getters,
}
