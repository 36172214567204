export default {
  documents: null,
  documentNameToCreate: '',
  documentDeletionPending: [],
  documentCreationPending: false,
  documentCreationMessage: {},
  currentDocument: null,
  documentStatusChangePending: false,
  documentStatusChangeMessage: {},
  documentRejectionMessage: {},
  documentRejectionPending: [],
  documentAcceptMessage: {},
  documentAcceptPending: [],
  documentDeleteMessage: {},
  documentDeletePending: [],
}
