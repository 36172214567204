export default {
  events: null,
  eventNameToCreate: '',
  eventDeletionPending: [],
  eventCreationPending: false,
  eventCreationMessage: {},
  eventUpdatePending: false,
  eventUpdateMessage: {},
  userEvents: [],
  currentEvent: {},
}
