import GenericDB from './generic-db'

export default class HospitalsDB extends GenericDB {
  constructor() {
    super('hospitals')
  }

  // Here you can extend HospitalsDB with custom methods
  // TODO Order alphabetically
}
